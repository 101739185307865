import React from 'react';
import { ThemeProvider } from 'styled-components';
// @ts-ignore
import resolveConfig from 'tailwindcss/resolveConfig';
// @ts-ignore
import tailwindConfig from '../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

const Theme = (props: any) => <ThemeProvider {...props} theme={theme}/>;

export default Theme;
