const plugin = require('tailwindcss/plugin');

module.exports = {
	theme  : {
		extend: {
			flex      : {
				'1/3': '1 3 0%',
				'2/3': '2 3 0%',
				'1/4': '1 4 0%',
				'2/4': '2 4 0%',
				'3/4': '3 4 0%',
			},
			fontFamily: {
				body   : ['Montserrat', 'sans-serif'],
				display: ['Montserrat', 'sans-serif'],
			},
			fontSize  : {
				'xs'  : '.75rem',
				'sm'  : '.875rem',
				'base': '1rem',
				'lg'  : '1.125rem',
				'xl'  : '1.25rem',
				'2xl' : '1.5rem',
				'3xl' : '1.875rem',
				'4xl' : '2.25rem',
				'5xl' : '3rem',
				'6xl' : '4rem',
				'7xl' : '5rem',
				'8xl' : '6rem',
				'9xl' : '7rem',
				'10xl': '8rem',
			},
			colors    : {
				primary         : {
					background: 'var(--primary)',
					text      : 'var(--primary-text)',
					100       : 'var(--primary-100)',
					200       : 'var(--primary-200)',
					300       : 'var(--primary-300)',
					400       : 'var(--primary-400)',
					500       : 'var(--primary-500)',
					600       : 'var(--primary-600)',
					700       : 'var(--primary-700)',
					800       : 'var(--primary-800)',
					900       : 'var(--primary-900)',
				},
				secondary       : {
					background: 'var(--secondary)',
					text      : 'var(--secondary-text)',
				},
				bg              : {
					background: 'var(--bg)',
					text      : 'var(--bg-text)',
				},
				accent          : {
					background: 'var(--accent)',
					text      : 'var(--accent-text)',
				},
				secondary_accent: {
					background: 'var(--secondary-accent)',
					text      : 'var(--secondary-accent-text)',
				},
				bright          : {
					background: 'var(--bright)',
					text      : 'var(--bright-text)',
				},
			},
		},
	},
	plugins: [
		plugin(function ({addBase, config}) {
			addBase({
				h1: {fontSize: config('theme.fontSize.2xl')},
				h2: {fontSize: config('theme.fontSize.xl')},
				h3: {fontSize: config('theme.fontSize.lg')},
			});
		}),
		require('@tailwindcss/ui'),
		require('@tailwindcss/typography'),
		require('@tailwindcss/custom-forms')
	],
	purge  : [
		'./src/**/*.tsx',
	],
};
